// typography.scss
// Define typography styles here

// typographic variables
$stevie-fallback: Arial, sans-serif;
$stevie: "stevie-sans", $stevie-fallback;
$futura-fallback: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
$futura: "futura-pt", $futura-fallback;
$futura-bold-fallback: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
$futura-bold: "futura-pt-bold", $futura-bold-fallback;
$futura-condensed-fallback: "Arial Narrow", Arial, sans-serif;
$futura-condensed: "futura-pt-condensed", $futura-condensed-fallback;

/// Font Stack Map to define wireframe font-stack
/// @author Hmphry
/// @link http://hmphry.com/useful-sass-mixins
///
/// @example scss - usage
///     h1 {
///         @include font(helvetica);
///     }
///
///     p {
///         @include font(helvetica, bold);
///     }
/// @example css - CSS output
///    h1 {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 400;
///        font-style: normal;
///    }
///
///    p {
///        font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
///        font-weight: 600;
///        font-style: normal;
///    }
///
/// NOTE: Needs a trailing semi-colon on the last declaration!
$font-stack: (
    group: "stevie",
    id: light,
    font: (
      $stevie,
    ),
    weight: 300,
    style: normal,
  ),
  (
    group: "stevie",
    id: book,
    font: (
      $stevie,
    ),
    weight: 400,
    style: normal,
  ),
  (
    group: "stevie",
    id: regular,
    font: (
      $stevie,
    ),
    weight: 500,
    style: normal,
  ),
  (
    group: "stevie",
    id: semibold,
    font: (
      $stevie,
    ),
    weight: 600,
    style: normal,
  ),
  (
    group: "stevie",
    id: bold,
    font: (
      $stevie,
    ),
    weight: 700,
    style: normal,
  ),
  (
    group: "stevie-fallback",
    id: light,
    font: (
      $stevie-fallback,
    ),
    weight: 300,
    style: normal,
  ),
  (
    group: "stevie-fallback",
    id: book,
    font: (
      $stevie-fallback,
    ),
    weight: 400,
    style: normal,
  ),
  (
    group: "stevie-fallback",
    id: regular,
    font: (
      $stevie-fallback,
    ),
    weight: 500,
    style: normal,
  ),
  (
    group: "stevie-fallback",
    id: semibold,
    font: (
      $stevie-fallback,
    ),
    weight: 600,
    style: normal,
  ),
  (
    group: "stevie-fallback",
    id: bold,
    font: (
      $stevie-fallback,
    ),
    weight: 700,
    style: normal,
  ),
  (
    group: "futura",
    id: light,
    font: (
      $futura,
    ),
    weight: 300,
    style: normal,
  ),
  (
    group: "futura",
    id: book,
    font: (
      $futura,
    ),
    weight: 400,
    style: normal,
  ),
  (
    group: "futura",
    id: regular,
    font: (
      $futura,
    ),
    weight: 500,
    style: normal,
  ),
  (
    group: "futura",
    id: demi,
    font: (
      $futura,
    ),
    weight: 600,
    style: normal,
  ),
  (
    group: "futura-fallback",
    id: light,
    font: (
      $futura-fallback,
    ),
    weight: 300,
    style: normal,
  ),
  (
    group: "futura-fallback",
    id: book,
    font: (
      $futura-fallback,
    ),
    weight: 400,
    style: normal,
  ),
  (
    group: "futura-fallback",
    id: regular,
    font: (
      $futura-fallback,
    ),
    weight: 500,
    style: normal,
  ),
  (
    group: "futura-fallback",
    id: demi,
    font: (
      $futura-fallback,
    ),
    weight: 600,
    style: normal,
  ),
  (
    group: "futurab",
    id: demi,
    font: (
      $futura-bold,
    ),
    weight: 600,
    style: normal,
  ),
  (
    group: "futurab-fallback",
    id: demi,
    font: (
      $futura-fallback,
    ),
    weight: 700,
    style: normal,
  ),
  (
    group: "futurac",
    id: book,
    font: (
      $futura-condensed,
    ),
    weight: 400,
    style: normal,
  ),
  (
    group: "futurac",
    id: bold,
    font: (
      $futura-condensed,
    ),
    weight: 700,
    style: normal,
  ),
  (
    group: "futurac-fallback",
    id: book,
    font: (
      $futura-condensed-fallback,
    ),
    weight: 400,
    style: normal,
  ),
  (
    group: "futurac-fallback",
    id: bold,
    font: (
      $futura-condensed-fallback,
    ),
    weight: 700,
    style: normal,
  );

@mixin font($group, $id: regular) {
  @each $font in $font-stack {
    @if ($group == map-get($font, group) and $id == map-get($font, id)) {
      font-family: map-get($font, font);
      font-weight: map-get($font, weight);
      font-style: map-get($font, style);
    }
  }
}

// Not setting font size on the html tag, so that users' font can scale via browser settings

// base styles
body {
  font-size: rem(18);
  line-height: 1.722;
  color: $drexel-grey-text;
  -webkit-font-smoothing: antialiased;

  .wf-loading & {
    @include font(stevie-fallback, light);
    letter-spacing: rem(0.5);
    word-spacing: rem(-1.85);
  }

  .wf-active & {
    @include font(stevie, light);
  }

  @media print {
    color: $black;
    font-size: rem(16);
    line-height: 1.5 !important; /* stylelint-disable-line declaration-no-important */
  }
}

// headings
h1,
h2,
h3,
h4,
h5,
h6,
.txt-h1,
.txt-h2,
.txt-h3,
.txt-h4,
.txt-h5,
.txt-h6 {
  @include zero;

  color: $drexel-grey-headline;
  display: block; // in case of applying classes to spans

  a > & {
    color: inherit;
  }
}

h1,
.txt-h1 {
  margin-bottom: rem(24);

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(32);
    line-height: 1.5;
    letter-spacing: rem(-0.35);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(40);
    line-height: 1.2;
  }

  @media (min-width: $bp-min-small-tablet) {
    .wf-loading & {
      font-size: rem(48);
      line-height: 1.416;
      letter-spacing: rem(-0.7);
    }

    .wf-active & {
      font-size: rem(60);
      line-height: 1.133;
    }
  }
}

h2,
.txt-h2 {
  margin-bottom: rem(36);

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(28);
    line-height: 1.416;
    letter-spacing: rem(-1.45);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(32);
    line-height: 1.235;
  }

  @media (min-width: $bp-min-small-tablet) {
    .wf-loading & {
      font-size: rem(31);
      line-height: 1.446;
      letter-spacing: rem(-0.7);
    }

    .wf-active & {
      font-size: rem(38);
      line-height: 1.184;
    }
  }
}

h3,
.txt-h3 {
  margin-bottom: rem(36);

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(24);
    line-height: 1.6;
    letter-spacing: rem(-0.25);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(30);
    line-height: 1.267;
  }
}

.txt-h3--secondary {
  margin-bottom: rem(15);

  .wf-loading & {
    @include font(futurab-fallback, demi);
    font-size: rem(17);
    line-height: 1.53;
    letter-spacing: rem(0.25);
    word-spacing: rem(1);
  }

  .wf-active & {
    @include font(futurab, demi);
    font-size: rem(20);
    line-height: 1.333;
  }
}

h4,
.txt-h4 {
  margin-bottom: rem(20);

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(22);
    line-height: 1.605;
    letter-spacing: rem(-0.85);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(26);
    line-height: 1.346;
  }
}

h5,
.txt-h5 {
  margin-bottom: rem(20);

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(16);
    line-height: 1.837;
    letter-spacing: rem(-0.2);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(20);
    line-height: 1.4;
  }
}

h6,
.txt-h6 {
  margin-bottom: rem(17);

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(14);
    line-height: 2.13;
    letter-spacing: rem(-0.35);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(17);
  }
}

// paragraphs
p {
  margin: 0;
  margin-bottom: rem(35);

  &:last-child {
    margin-bottom: 0;
  }
}

// small text
.small {
  font-size: rem(18);
  margin: 0;
  margin-bottom: rem(28);

  &:last-child {
    margin-bottom: 0;
  }
}

// xsmall text
.xsmall {
  font-size: rem(15);
  margin: 0;
  margin-bottom: rem(23);

  &:last-child {
    margin-bottom: 0;
  }

  a {
    border-bottom-color: transparent;

    &:hover,
    &:focus {
      border-bottom-color: currentColor;
    }
  }
}

@media print {
  // Typography for print

  p,
  .small,
  .xsmall {
    margin-bottom: $print-standard-text-whitespace !important; /* stylelint-disable-line declaration-no-important */

    &:last-child {
      margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  // Headers using ratio-based type scales: https://moderncss.dev/generating-font-size-css-rules-and-creating-a-fluid-type-scale/
  $type-ratios: (
    "minorSecond": 1.067,
    "majorSecond": 1.125,
    "minorThird": 1.2,
    "majorThird": 1.25,
    "perfectFourth": 1.333,
    "augmentedFourth": 1.414,
    "perfectFifth": 1.5,
    "goldenRatio": 1.618,
  );

  @function type-ratio($key) {
    @return map-get($type-ratios, $key);
  }

  $type-base-size: 0.875rem;
  // Select by key of map, or use a custom value
  $type-size-ratio: type-ratio(
    "minorThird"
  ); // Closest to Drexel's existing typography ratio
  // List in descending order to prevent extra sort function
  $type-levels: 6, 5, 4, 3, 2, 1;
  $level-size: $type-base-size;

  @each $level in $type-levels {
    $level-size: $level-size * $type-size-ratio;
    // Output heading styles
    // Assign to element and create utility class
    /* stylelint-disable declaration-no-important */
    h#{$level},
    .txt-h#{$level} {
      break-after: avoid-page;
      page-break-after: avoid;
      break-inside: avoid-page;
      page-break-inside: avoid;
      font-family: futura-pt, "Lucida Grande", "Lucida Sans Unicode", sans-serif !important;
      font-size: $level-size !important;
      font-weight: 400 !important;
      letter-spacing: 0 !important;
      line-height: calc(5px + 2ex + 5px) !important;
      margin-bottom: 0.65em !important;
      margin-top: $print-standard-component-whitespace !important;
      padding: 0 !important;
      text-align: left !important;

      & + p {
        break-before: avoid-page;
        page-break-before: avoid;
      }
    }
  }

  h1 {
    margin-top: 0 !important; // h1 should generally come first
  }

  // Smaller spacing when a heading directly follows another heading
  /* Commenting out for further testing - doesn't look great on Contact page
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6,
  .txt-h2 + .txt-h3,
  .txt-h3 + .txt-h4,
  .txt-h4 + .txt-h5,
  .txt-h5 + .txt-h6,
  h2 + .txt-h3,
  h3 + .txt-h4,
  h4 + .txt-h5,
  h5 + .txt-h6,
  .txt-h2 + h3,
  .txt-h3 + h4,
  .txt-h4 + h5,
  .txt-h5 + h6 {
    margin-top: $print-standard-text-whitespace !important;
  }
  */
  /* stylelint-enable declaration-no-important */
}
