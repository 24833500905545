@import "../base/_stacking-context.scss";

// For hiding from all users
.is-hidden {
  display: none;
}

// Hides visually but not from screen readers
.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
  width: auto;
}

/* Adds a vertical gradient stripe on MOBILE ONLY and the appropriate padding to shove what it contains over a bit */
.mobile-striper {
  @include componentize();

  @include component-normalize();

  padding: rem(32) 0 0 rem(32);
  position: relative;

  & > * {
    margin: 0; /* essentially remove componentize on immmediate children and take responsibility for it */
    position: relative;
    z-index: $stack-internal;
  }

  @media (min-width: $bp-min-small-tablet) {
    padding: 0;
  }

  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: rem(63);

    @media (min-width: $bp-min-small-tablet) {
      display: none;
    }
  }

  @media print {
    padding: 0;

    &::after {
      display: none;
    }
  }
}

/* Sometimes we have a button that doesn't really associate directly with a component -- this'll do it */
.standalone-button {
  @include componentize();

  @include component-normalize();

  display: flex;
  justify-content: center;
  margin-top: rem(-11); /* Tighten this up to previous component a little */
  padding-bottom: rem(32); /* and force breathing room below */
  width: 100%;

  &.standalone-button--top-pad {
    margin-top: rem(32);
  }

  &.standalone-button--hide-mobile {
    display: none;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-top: rem(-21); /* Tighten this up to previous component a little */

    &.standalone-button--hide-mobile {
      display: flex;
    }
  }

  @media print {
    @include print-list;
    padding-bottom: 0;

    &.js-simple-more {
      display: none;
    }
  }

  .btn {
    text-align: center;
    width: 100%;

    @media (min-width: $bp-min-small-tablet) {
      width: auto;
    }

    @media print {
      text-align: left;
    }
  }
}

/* Helper for primary nav in mobile */
body.is-scroll-frozen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Helper for list-app container; didn't seem to deserve its own BEM block */
.list-app {
  @include component-normalize();

  /* This is always the last child because of the way it works, so this is a little hack to give it margining */
  &:last-child {
    margin-bottom: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-bottom: $base-component-whitespace;
    }
  }
}

// CSS columns, to allow for columns within text without needing extra markup
.text-columns {
  column-count: 1;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  > li {
    break-inside: avoid;
    column-break-inside: avoid;
    list-style: none;
    overflow: hidden; // to fix multicol wrap issues in IE11
    padding-left: rem(21);
    position: relative;

    &::before {
      content: "";
      background-color: currentColor;
      border-radius: 50%;
      display: inline-block;
      height: 5px;
      left: 0;
      position: absolute;
      top: rem(14);
      width: 5px;
    }
  }

  @media (min-width: $bp-min-small) {
    &.text-columns--2-up,
    &.text-columns--3-up,
    &.text-columns--4-up {
      column-count: 2;
      column-gap: rem(42);
    }
  }

  @media (min-width: $bp-min-large) {
    &.text-columns--3-up {
      column-count: 3;
    }

    &.text-columns--4-up {
      column-count: 4;
    }
  }
}
