svg {
  display: block;

  path {
    fill: currentColor;
  }

  polygon {
    fill: currentColor;
  }
}
