@mixin checkbox-radio {
  cursor: pointer;
  display: block;
  padding-left: rem(29);
  position: relative;

  &::before {
    border: 2px solid currentColor;
    border-radius: 4px;
    content: "";
    display: block;
    height: rem(17);
    position: absolute;
    top: 10px;
    left: 0;
    width: rem(17);
  }

  &::after {
    content: "";
    display: none;
    height: rem(13);
    position: absolute;
    top: 12px;
    left: 7px;
    width: rem(13);
  }
}

@mixin checkbox-only {
  &:after {
    border: 2px solid currentColor;
    border-top: none;
    border-left: none;
    display: block;
    height: 10px;
    width: 5px;
    transform: rotate(45deg);
  }
}

@mixin radio-only {
  &:after {
    background-color: currentColor;
    border-radius: 50%;
    display: block;
    height: rem(11);
    left: rem(5);
    top: rem(15);
    width: rem(11);
  }
}

label {
  display: block;
  font-size: rem(20);
  line-height: 2;
  text-transform: uppercase;

  .wf-loading & {
    @include font(futurab-fallback, demi);
    letter-spacing: rem(0.3);
  }

  .wf-active & {
    @include font(futurab, demi);
    letter-spacing: 1.6px;
  }

  input[type="checkbox"] + &,
  input[type="radio"] + & {
    @include checkbox-radio;
  }

  // Have to separate :has rules or Firefox will bail on all
  &:has(input[type="checkbox"]),
  &:has(input[type="radio"]) {
    @include checkbox-radio;
  }

  // All browsers
  input[type="radio"] + & {
    &::before {
      border-radius: 50%;
    }
  }

  // Those that understand :has
  &:has(input[type="radio"]) {
    &::before {
      border-radius: 50%;
    }
  }
}

input,
textarea {
  border: 1px solid currentColor;
  font-family: inherit;
  font-size: rem(21);
  display: block;
  width: 100%;
  padding: rem(17);
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

// All browsers
input[type="checkbox"]:checked + label {
  @include checkbox-only;
}

// Those that understand :has
label:has(input[type="checkbox"]:checked) {
  @include checkbox-only;
}

// All browsers
input[type="radio"]:checked + label {
  @include radio-only;
}

// Those that understand :has
label:has(input[type="radio"]:checked) {
  @include radio-only;
}

select {
  -moz-appearance: window;
  -webkit-appearance: none;

  background-color: $white;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJBAMAAADJBLEBAAAAAXNSR0IB2cksfwAAABhQTFRFAAAAbKzkbKzkbKzkcK/fbKzkaqrkba3kkPGwtQAAAAh0Uk5TAKD/0BDwMGDqVCa+AAAAPklEQVR4nGMQUgIDRQZGCEOAgcEIRCszMDA4gxgmQAZLkJKSqgOQwZCqpBQGohnYlJQSwAyGInUIzcBeACQASooHcZyxOe8AAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: calc(100% - #{rem(21)}) center;
  border: 1px solid currentColor;
  cursor: pointer;
  font-size: rem(21);
  display: block;
  padding: rem(17) rem(45) rem(17) rem(17);
  width: 100%;

  // Multi-selects shouldn't have the arrow
  &[multiple] {
    background: none;
  }
}

select::-ms-expand {
  display: none;
}

option {
  font-weight: 300;
}

option[disabled] {
  font-weight: 300;
  opacity: 0.8;
}

//CSS Specific styles for form elements

.form__indent-text{
  padding-left: rem(29) !important;
  margin-bottom: 0;
}