// Slate Forms structure

.form-slate {
  // Nesting in this case is necessary, as we're overriding 3rd party stylesheets

  select:not(:only-child) {
    display: inline-block;
    width: auto;
  }

  div.form_response input + label {
    display: inline-block;
  }

  .form_label {
    // Some repetition from base/_forms, to catch form labels that aren't labels
    display: block;
    font-size: rem(20);
    line-height: 1.25;
    text-transform: uppercase;

    .wf-loading & {
      @include font(futurab-fallback, demi);
      letter-spacing: rem(0.3);
    }

    .wf-active & {
      @include font(futurab, demi);
      letter-spacing: 1.6px;
    }
  }

  label.form_label {
    line-height: 2;
  }

  // Give an asterisk to labels before a required field
  [data-required="1"] .form_label:after {
    content: "*";
  }

  .form_label + .form_responses {
    margin-bottom: 1em;
  }

  .form_h2 .form_label {
    // Much repetition from base/_typography
    @include zero;

    color: $drexel-grey-headline;
    margin-bottom: rem(24);
    margin-top: rem(48);

    .wf-loading & {
      @include font(futura-fallback, book);
      font-size: rem(28);
      line-height: 1.416;
      letter-spacing: rem(-1.45);
    }

    .wf-active & {
      @include font(futura, book);
      font-size: rem(32);
      line-height: 1.235;
    }

    @media (min-width: $bp-min-small-tablet) {
      .wf-loading & {
        font-size: rem(31);
        line-height: 1.446;
        letter-spacing: rem(-0.7);
      }

      .wf-active & {
        font-size: rem(38);
        line-height: 1.184;
      }
    }
  }

  .form_button_submit {
    @extend .btn;

    margin-top: $base-half-component-whitespace;
  }
}
