// general.scss
// Define universal plain tag styles here
// NOTE: No classes or IDs!

* {
  box-sizing: border-box;


  /* stylelint-disable declaration-no-important */
  @media print {
    background: none !important;
    box-shadow: none !important;
    text-shadow: none !important;

    &,
    &:hover,
    &:focus {
      transition: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

// Focus styles
*:focus-visible,
a:focus-visible,
a.btn:focus-visible,
a svg:focus-visible,
button:focus-visible {
  outline: 3px solid $black;
  outline-style: auto;
  box-shadow: 3px 3px 4px $white; 
  transition: outline 0.2s ease-in, box-shadow 0.2s ease-in;
  scroll-behavior: smooth;
}

textarea:focus-visible,
select:focus-visible,
input[type="number"]:focus-visible,
input[type="file"]:focus-visible,
input[type="date"]:focus-visible,
input[type="text"]:focus-visible,
input[type="tel"]:focus-visible,
input[type="email"]:focus-visible,
input[type="password"]:focus-visible {
  outline: 2px solid $drexel-blue;
}

label:has(input[type=checkbox]:focus-visible)::before, 
label:has(input[type=radio]:focus-visible)::before {
  outline: 3px solid $drexel-blue;
}

:root {
  scroll-behavior: auto;

  // Enable smooth scrolling only if no preference for reduced motion
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

// body element
body {
  background-color: $white;
  margin: 0;
}

// Annotate breakpoints inside body psuedo-selector
// so they may be read by javascript
// More details: https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
body::before {
  content: "xxsmall";
  display: none;

  @media (min-width: $bp-min-xsmall) {
    content: "xsmall";
  }

  @media (min-width: $bp-min-small) {
    content: "small";
  }

  @media (min-width: $bp-min-small-tablet) {
    content: "small-tablet";
  }

  @media (min-width: $bp-min-medium) {
    content: "medium";
  }

  @media (min-width: $bp-min-large) {
    content: "large";
  }

  @media (min-width: $bp-min-xlarge) {
    content: "xlarge";
  }
}

// Identify pre @supports browsers for use with javascript
body::after {
  content: "ie";
  display: none;

  /*
    IE 11 doesn't do @supports, so this can be any valid CSS that doesn't
    also exclude another browser.  Sticky is good because it's our primary
    IE 11 exclusion.
  */
  @supports (position: sticky) {
    content: "";
  }
}

// Do not remove commenting, will break tour map
// main {
//   overflow-y: hidden;
//   max-width: 100%;
// }

li {
  /* why li?  We focus li directly for use in the primary nav */
  outline: none;
  box-shadow: none;

  &:visited,
  &:target {
    outline: none;
    box-shadow: none;
  }
}

a,
button {
  cursor: pointer;
}

// flexible media
object,
video {
  display: block;
  max-width: 100%;
}

// lists
ol,
ul {
  padding: 0;
  margin-top: 0;
  margin-left: $base-component-whitespace;
  margin-bottom: $base-half-component-whitespace;

  li {
    margin-bottom: rem(10);
  }

  table & {
    margin-top: rem(10);
    margin-left: 1em;
  }

  table.is-interactive & {
    margin-top: 0;
  }
}

// nested lists
ul ul,
ol ul,
ol ol,
ul ol {
  margin-top: rem(10);
}

// definition lists
dl {
  padding: 0;
  margin-top: 0;
  margin-left: $base-component-whitespace;
  margin-bottom: $base-half-component-whitespace;

  dd,
  dt {
    margin-bottom: rem(10);
  }
}

// links
a {
  border-bottom: rem(4) solid var(--link-border, #{rgba($base-link-color, 0.5)});
  // Rare use of specific color here because this does need to be clearly deliminated from the text; override in theme when necessary
  color: var(--link-color, #{$base-link-color});
  font-weight: 700;
  cursor: pointer;
  transition: color $standard-transition, border-color $standard-transition;
  text-decoration: none;

  &:hover,
  &:focus {
    border-bottom-color: var(--link-hover-border, #{$drexel-blue-dark});
    color: var(--link-hover-color, #{$drexel-blue-dark});
  }

  &[href^="tel:"] {
    color: inherit;
    border-bottom-color: transparent;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    border-bottom: none !important;
    color: $drexel-blue !important;
    font-family: "stevie-sans", Arial, sans-serif !important;
    font-weight: 700 !important;
    text-decoration: underline !important;

    &[href]:after {
      content: " <" attr(href) ">";
      font-weight: 300 !important;
      text-transform: none !important;
      font-size: 1rem !important;
    }

    &[href^="/"]:not([href^="//"]):after
    {
      content: " <https://drexel.edu" attr(href) ">";
    }

    &[href^="tel:"] {
      color: inherit !important;
      font-weight: 300 !important;
    }

    &[href^="tel:"]:after,
    &[href^="mailto:"]:after,
    &[href*="google.com/map"],
    &[href*="javascript"],
    &:has(.news-listing__image):after,
    .search-item__media &:after,
    .tour__map-container &:after,
    .explore-map__map-container &:after {
      display: none !important;
    }

    &:hover,
    &:focus {
      border-bottom: none !important;
    }

    span {
      text-decoration: underline !important;
    }

    &[href^="tel:"] span {
      text-decoration: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

img {
  height: auto;
  max-width: 100%;
}

p,
li {
  @media print {
    widows: 3;
    orphans: 3;
  }
}

// hr
hr {
  border-bottom: 1px solid $base-border-color;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: -1px 0 0;
  box-sizing: content-box;
}

figure {
  margin: 0;
}

table {
  border-collapse: collapse;
  margin-bottom: $base-component-whitespace;

  @include font(stevie, book);
  font-size: rem(14);

  @media (min-width: $bp-min-small-tablet) {
    font-size: rem(18);
  }

  @media print {
    font-weight: inherit;
  }
}

tr {
  border: none;
  border-bottom: 1px solid $base-border-color;
}

th,
td {
  border: none;

  line-height: 1.588;
  padding: rem(14);
  text-align: left;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

th {
  @include font(stevie, bold);
  font-size: rem(18);
  line-height: 1.266666666666667;

  @media (min-width: $bp-min-xlarge) {
    white-space: wrap;// Table Headers should wrap on xlarge screens to avoid horiz scrollbar
  }
}

img,
picture {
  display: block;
}

strong {
  font-weight: 700;
}

address {
  font-style: normal;
}

@media print {
  @page {
    size: auto;
    margin: 12.7mm; // 0.5 inch
  }

  img,
  figure,
  table,
  tr {
    break-inside: avoid-page;
    page-break-inside: avoid;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}
