// buttons.scss

// base
.btn {
  font-size: rem(17);
  line-height: 1;
  background: $button-bg-color;
  border: 2px solid $button-color;
  color: $button-color;
  display: block;
  padding: 20px 32px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color $standard-transition, background $standard-transition,
    border-color $standard-transition;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 100%;

  .btn-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wf-loading & {
    @include font(futurab-fallback, demi);
    letter-spacing: rem(1.25);
  }

  .wf-active & {
    @include font(futurab, demi);
    letter-spacing: 2.55px;
  }

  &:active,
  &:hover {
    border-color: $black; /* For accessibility; turn off in themes only if you have a different highlight state set */
    outline: 0;
  }

  @media (min-width: $bp-min-small) {
    display: inline-block;
    width: auto;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    background: none;
    border: none;
    display: block;
    font-size: inherit !important;
    letter-spacing: 0 !important;
    line-height: inherit;
    padding: 0 !important;
    text-align: inherit;
    text-transform: none;

    &:active,
    &:hover,
    &:focus {
      background: none;
      border: none;
    }

    &:before {
      display: none !important;
    }

    // Style paragraphs with buttons in them like unordered lists
    p > & {
      @include print-list;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.btn--icon {
  svg {
    display: inline-block;
    height: rem(21);
    line-height: 0;
    margin: rem(-4) 0 0 0;
    position: relative;
    top: rem(4);
    width: rem(21);
  }

  &.btn--secondary svg {
    height: rem(16);
    margin: rem(-6) 0 0 0;
    top: rem(2);
    width: rem(16);
  }
}

// modifiers
// .btn--primary is default and implied
.btn--secondary,
.btn--tertiary,
.btn--taxonomy {
  font-size: rem(13);
  padding: rem(10) rem(12);
}

// icon centered in button

.btn--primary {
  &.icon-link {
    width: fit-content;
    display: flex;
    align-items: center;
  }
  .icon-link__icon {
    top: -1px;
  }
}
.btn--secondary {
  &.icon-link {
    width: fit-content;
    display: flex;
    align-items: center;
  }
  .icon-link__icon {
    top: -1px;
  }
}

.btn--tertiary,
.btn--taxonomy {
  display: inline-block;
  line-height: 1.4; /* These guys may actually wrap */
  padding: rem(4) rem(12);
  width: auto;
}

.btn--arrow {
  &:after {
    @include verticaltriangle(rem(8), rem(8));

    content: "";
    display: inline-block;
    margin-left: rem(11);
    position: relative;
    top: -2px;
    transform: rotateX(180deg);
  }
}

.btn--link {
  @include font(stevie, bold);

  border: none;
  font-size: rem(18);
  padding: rem(21);
  line-height: 1.722;
}

@media print {
  .btn--taxonomy {
    display: none;
  }
}
