// mixins.scss
// Define custom mixins here

// color variables
$base-outline-color: $grey-500 !default;
$base-fill-color: $grey-300 !default;

// General rules for individual components
@mixin component-normalize {
  margin-bottom: $base-component-mobile-whitespace;
  opacity: 0.999; // reset internal stacking context

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: $base-component-whitespace;
  }

  @media print {
    margin-bottom: $print-standard-component-whitespace !important; /* stylelint-disable-line declaration-no-important */
  }
}

@mixin containerize {
  margin: 0 auto;
  margin-bottom: $base-component-mobile-whitespace;
  max-width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: $base-component-whitespace;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-component-whitespace !important;
    max-width: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

@mixin componentize {
  margin: 0 auto;
  margin-bottom: $base-component-mobile-whitespace;
  max-width: calc(100% - #{$double-side-margin});

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: $base-component-whitespace;
  }

  @media (min-width: $bp-min-xxlarge) {
    max-width: $max-width;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-component-whitespace !important;
    max-width: none !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}
@mixin fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: none;
  z-index: 100;
}

@mixin componentize-fixed {
  padding: 0 calc((100% - $max-width )/ 2);
  //margin-bottom: $base-component-mobile-whitespace;
  max-width: calc(100% - #{$double-side-margin});

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    //margin-bottom: $base-component-whitespace;
  }
  @media (max-width: $bp-min-xxlarge) {
    margin: 0 $standard-side-margin;
    max-width: calc(100% - $double-side-margin);
  }
  @media (min-width: $bp-min-xxlarge) {


    max-width: $max-width;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-component-whitespace !important;
    max-width: none !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}



@mixin componentize-wide {
  margin: 0 auto rem(70);
  max-width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-component-whitespace !important;
    max-width: none !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

// Overlapping components or containers
@mixin overlap($direction, $amount) {
  $direction-to-position: (
    up: top,
    right: right,
    left: left,
    down: bottom,
    margin-up: margin-top,
    margin-down: margin-bottom,
  );

  position: relative;

  @if map-has-key($direction-to-position, $direction) {
    #{map-get($direction-to-position, $direction)}: #{$amount};
  }

  @if $direction == "right" or $direction == "bottom" {
    z-index: $stack-overlaps;
  }
}

// Builds gradient variant classes for all the permitted colors in gradients (defined in _colors.scss)
@mixin create-gradient-variants(
  $gradients,
  $baseclass,
  $opacity,
  $angle,
  $after
) {
  @each $name1, $color1 in $gradients {
    @each $name2, $color2 in $gradients {
      @if $after == "true" {
        .#{$baseclass}--#{$name2}-#{$name1}:after {
          background-image: linear-gradient(
            $angle,
            rgba($color1, $opacity) 0%,
            rgba($color2, $opacity) 100%
          );
        }
      } @else {
        .#{$baseclass}--#{$name2}-#{$name1} {
          background-image: linear-gradient(
            $angle,
            rgba($color1, $opacity) 0%,
            rgba($color2, $opacity) 100%
          );
        }
      }
    }
  }
}

@mixin create-gradient-custom-props($gradients, $baseclass) {
  @each $name1, $color1 in $gradients {
    @each $name2, $color2 in $gradients {
      .#{$baseclass}--#{$name1}-#{$name2} {
        --start-color: #{$color1};
        --end-color: #{$color2};
      }
    }
  }
}

@mixin font-smoothing($fontsmoothing: "on") {
  @if $fontsmoothing == "on" {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Make a CSS triangle
@mixin triangle($height, $width) {
  border-left: $width solid currentColor;
  border-top: $height solid transparent;
  border-bottom: $height solid transparent;
  content: "";
  display: block;
}

// Same thing, just rotated for use with rotateX
@mixin verticaltriangle($height, $width) {
  border-bottom: $width solid currentColor;
  border-left: $height solid transparent;
  border-right: $height solid transparent;
  content: "";
  display: block;
}

// zero out padding and margins
@mixin zero() {
  margin: 0;
  padding: 0;
}

// zero out list-style-type NOTE: Becareful with nested list styles
@mixin zero-list() {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

@mixin print-style-general(){
  display: block !important;
  position: relative !important;
  margin-bottom: 2rem !important;
}

// Print styles for faux lists
@mixin print-list() {
  break-inside: avoid-page;
  page-break-inside: avoid;
  display: list-item !important; /* stylelint-disable-line declaration-no-important */
  margin-left: $base-component-whitespace !important; /* stylelint-disable-line declaration-no-important */
  margin-bottom: $print-standard-listitem-whitespace !important; /* stylelint-disable-line declaration-no-important */

  &::marker {
    color: $black;
  }
}

// Provides an easy way to include a clearfix for containing floats.
//
// @link http://cssmojo.com/latest_new_clearfix_so_far/
//
// @example scss - Usage
//   .element {
//     @include clearfix;
//   }
//
// @example css - CSS Output
//   .element::after {
//     clear: both;
//     content: "";
//     display: table;
//   }

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

// A mixin to create a floating :before pseudo-element on a container to make space for the Share button.
// Takes in start and end breakpoints
@mixin spaceForShare($start, $end) {
  @media (min-width: $start) and (max-width: $end) {
    &:before {
      content: "";
      width: $share-component-spacer;
      height: $share-component-vspacer;
      display: block;
      float: right;
    }
  }
}
