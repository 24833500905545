// Adding some specificity to make sure we're targeting Sitecore Forms

form[action*="formbuilder?"] {
  label {
    margin-left: 0;
    margin-top: rem(16);
  }

  // Tighter spacing for checkbox/radio lists
  label + label:has(input[type="radio"]),
  label + label:has(input[type="checkbox"]),
  label + input[type="hidden"] + label:has(input[type="radio"]),
  label + input[type="hidden"] + label:has(input[type="checkbox"]),
  label:has(input[type="radio"]) + label:has(input[type="radio"]),
  label:has(input[type="checkbox"]) + label:has(input[type="checkbox"]) {
    margin-top: 0;
  }

  // Fix up checkboxes and radio buttons in Firefox (using provided HTML structure)
  @supports not (selector(:has(*))) {
    input[type="checkbox"],
    input[type="radio"] {
      position: relative !important;
      display: inline-block;
      vertical-align: middle;
      margin-inline-end: rem(5);
      top: -0.1em;
      clip: auto;
      overflow: visible;
      height: auto;
      width: auto;
    }
  }

  // Give an asterisk to labels before a required field
  // Have to separate :has rules or Firefox will bail on all

  // All browsers
  .label--required {
    &:after {
      content: "*";
    }
  }

  // Those that understand :has
  label:has(+ [data-val-required]) {
    &:after {
      content: "*";
    }
  }

  // Multi-selects shouldn't have the arrow
  select[size] {
    background: none;
  }

  // Error message display
  .field-validation-error {
    display: block;
    color: $base-error-color;
    font-style: italic;
  }

  // Submit button
  [type="submit"] {
    @extend .btn;

    margin-top: $base-half-component-whitespace;
  }
}
